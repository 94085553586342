<template>
    <div>
        <b-collapse id="collapse-1">
            <b-card
            no-body
            class="mb-2"
            >
                <b-container fluid>
                    <validation-observer
                        ref="formFilters"
                    >
                        <!-- Form -->
                        <b-row class="p-1">
                            <b-col cols="12" md="3">                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="cliente"
                                >
                                <b-form-group
                                    label="Cliente"
                                    label-for="customer"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <v-select 
                                        v-model="tableSettings.customer" 
                                        :options="customers"
                                        clearable
                                        trim
                                        :reduce="val => val.id"
                                        :state="errors.length > 0 ? false:null"
                                        label="name"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="2">
                                <validation-provider
                                #default="{ errors }"
                                name="estado"
                                >
                                <b-form-group
                                    label="Estado"
                                    label-for="status"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <v-select 
                                     label="name"
                                     v-model="tableSettings.status" 
                                     :options="status"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="auto">
                                <validation-provider
                                    #default="{ errors }"
                                    name="desde"
                                >
                                <b-form-group
                                    label="Desde"
                                    label-for="begins"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="begins"
                                        trim
                                        v-model="tableSettings.begins"
                                        type="date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="auto">
                                <validation-provider
                                #default="{ errors }"
                                name="hasta"
                                >
                                <b-form-group
                                    label="Hasta"
                                    label-for="ends"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="ends"
                                        trim
                                        v-model="tableSettings.ends"
                                        type="date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="auto" >
                                <validation-provider
                                #default="{ errors }"
                                name="busqueda"
                                >
                                <b-form-group
                                    label="Busqueda"
                                    label-for="search"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="search"
                                        trim
                                        :disabled="formDisabled"
                                        v-model="tableSettings.searchQuery" 
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="auto">
                                <b-button variant="outline-secondary" class="btn-icon mt-2" @click="clearFilter">
                                    <feather-icon icon="XIcon" /> Limpiar
                                </b-button>
                                <b-button variant="secondary" class="btn-icon ml-1 mt-2" @click="searchFilter">
                                    <feather-icon icon="SearchIcon" /> Filtrar
                                </b-button>
                            </b-col>
                        </b-row>
                        <!-- Fin Form -->
                       
                    </validation-observer>
                </b-container>
            </b-card>
        </b-collapse>

        <b-card
        no-body
        class="mb-0"
        >
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="3">
                        <label>Mostrar</label>
                        <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                        class="per-page-selector d-inline-block mx-50" />
                        <label>registros</label>
                    </b-col>
                    <b-col cols="12" md="9" align="right">
                        <b-button variant="outline-secondary" v-b-toggle.collapse-1 class="btn-icon mr-1">
                            <feather-icon icon="FilterIcon" /> Filtrar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <b-table
                :busy="tableSettings.busy"
                ref="deliveryTable"
                class="position-relative"
                :items="dataTable"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="tableSettings.sortBy"
                show-empty
                empty-text="No se encontraron datos"
                :sort-desc.sync="tableSettings.sortDesc"
            >

            <!-- Column: Actions -->
            <template #cell(actions)="data">
                <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                >

                    <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </template>
                    <b-dropdown-item @click="detail(data.item)">
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalle</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

            </b-table>

            <b-container class="mb-5">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                        v-model="tableSettings.page"
                        :total-rows="totalRows"
                        :per-page="tableSettings.perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
 </template>

<script>
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

export default {
    name: 'AdminDeliveries',
    components: { vSelect, 
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            perPageOptions: [10, 25, 50, 100],
            tableColumns: [
                { key: 'customer.name', label: 'Farmacia',sortable: true  },
                { key: 'person.names', label: 'Persona',sortable: true  },
                { key: 'status', label: 'Estado', sortable: true },
                { key: 'municipality', label: 'Municipio', sortable: true },
                { key: 'created_at', label: 'Fecha', sortable: true },
                { key: 'updated_at', label: 'Actualización', sortable: true },
                { key: 'actions', label: 'acciones' },
            ],
            sortBy: 'created_at',
            isSortDirDesc: true,
            totalRows: 0,   
            dataMeta: {
                from: 0,
                to: 0,
                of: 0,
            },
            dataTable: [],
            tableSettings: {
                begins: null,
                ends: null,
                customer: null,
                status: null,
                searchQuery: '',
                perPage: 10,
                page: 1,
                sortBy: null,
                sortDesc: false,
                busy: false,
            },
            customers: [],
            status: [],
            formDisabled: false
        }
    },
    methods: {
        searchFilter() {
            this.getTableData();
        },
        getStatus() {
            this.$http.get('/deliveries/status').then(({data}) => {
                this.status = data;
            })
        },
        async getCustomers() {
            await this.$http.get('/users/get-customers')
            .then(({data}) => {
                this.customers = data
            })
        },
        detail(item) {
            this.$router.push({ name: 'admin-deliveries-detail', params: { id: item.id } })
        },
        async getTableData() {
            this.tableSettings.busy = true;
            await this.$http.get('/deliveries', { params: this.tableSettings }).then((response) => {
                this.dataTable = response.data.deliveries
                this.totalRows = response.data.total
                this.dataMetaCounter()
            })
            this.tableSettings.busy = false;
        },
        dataMetaCounter() {
            const localItemsCount = this.dataTable.length
            this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
            this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
            this.dataMeta.of = this.totalRows
        },
        changeDeliveryStatus()
        {
            this.$swal.showLoading();
        },
        clearFilter() {
            this.tableSettings = {
                begins: null,
                ends: null,
                customer: null,
                status: null,
                searchQuery: '',
                perPage: 10,
                page: 1,
                sortBy: null,
                sortDesc: false,
                busy: false,
            };
            this.getTableData();
        }
    },
    created(){
        this.getCustomers();
        this.getStatus();
        this.getTableData();
    },
    watch: {
        "tableSettings.sortBy": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.sortDesc": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.perPage": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.page": {
            handler(val) {
                this.getTableData()
            },
        },
    }
} 
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>